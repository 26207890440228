import { randomString } from "@/helpers/common"
import { omitBy, isUndefined, cloneDeep } from "lodash-es"

export default {
  watch: {
    "$route.query": {
      handler(value, oldValue) {
        if (value._key !== oldValue._key) {
          this.loadFromQuery(value, true)
        }
      },
      deep: true
    }
  },

  methods: {
    async loadFromQuery({ ota_id, shop_id, car_class_id, insurance }, keepDepartureDate = false) {
      const originalFilters = cloneDeep(this.filters)

      this.loadInsuranceFromQuery(insurance)
      const ota = await this.loadOtaFromQuery(ota_id, originalFilters)
      const shop = await this.loadShopFromQuery(shop_id, originalFilters)
      await this.loadCarClassFromQuery(car_class_id, ota, shop, keepDepartureDate)
    },

    loadInsuranceFromQuery(insurance) {
      if (insurance === undefined) return

      this.changeFilters({ insurance: { title: insurance } })
    },

    async loadOtaFromQuery(ota_id, filters) {
      if (ota_id === undefined) return

      const ota = this.priceCalendarAllowedOtas.find(el => el.id === Number(ota_id))

      if (!ota) {
        return this.updateUrl({})
      }

      const shouldReloadShops = ota.id !== filters.ota.id
      this.changeFilters(this.getNewFiltersByChange("ota", ota))
      shouldReloadShops && (await this.fetchShopsItems())

      return ota
    },

    async loadShopFromQuery(shop_id, filters, ota) {
      if (shop_id === undefined) return

      const shop = this.shopsItems.find(el => el.id === Number(shop_id))

      if (!shop) {
        return this.updateUrl(this.queryFromFilters({ ota }, false))
      }

      const shouldReloadCarClasses = shop.id !== filters.shop.id
      this.changeFilters(this.getNewFiltersByChange("shop", shop))
      shouldReloadCarClasses && (await this.fetchCarClassesItems())

      return shop
    },

    async loadCarClassFromQuery(car_class_id, ota, shop, keepDepartureDate) {
      if (car_class_id === undefined) return

      const carClass = this.carClassesItems.find(el => el.id === Number(car_class_id))

      if (!carClass) {
        return this.updateUrl(this.queryFromFilters({ ota, shop }, false))
      }

      this.changeFilters(this.getNewFiltersByChange("carClass", carClass))

      await this.fetchCalendarData(keepDepartureDate)
    },

    loadFromFilters(filters) {
      this.updateUrl(this.queryFromFilters(filters))
    },

    queryFromFilters(filters = this.filters, update_key = true) {
      const query = {
        ota_id: filters.ota.id,
        shop_id: filters.shop.id,
        car_class_id: filters.carClass.id,
        insurance: filters.insurance.title || this.filters.insurance.title,
        _key: update_key ? randomString() : this.$route.query._key
      }

      return omitBy(query, isUndefined)
    },

    async updateUrl(query = this.queryFromFilters(this.filters)) {
      await this.$router.replace({ query }).catch(() => {})
    }
  }
}
