import { api } from "@/config"
import { mapFilters } from "@/config/store/price_management/actions.js"

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit }, filters = {}) => {
    const params = { filters: mapFilters(filters) }
    const response = await api.get(baseURI, { params })
    commit("SET_ITEMS", response.data.data)
  }
})
