import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import extractActions from "./actions"

const BASE_URI = "/competitors_prices"

const store = new StoreItemsModule({
  baseURI: BASE_URI
})

store.mergeState({
  items: []
})

const { FETCH_ITEMS } = extractActions({ baseURI: BASE_URI })
store.mergeActions({ FETCH_ITEMS }, withLoading)

export default store
