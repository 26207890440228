<template lang="pug">
  .price-list
    Filters(
      :current-plan="currentPlan"
      :filters="filters"
      :otas="priceCalendarAllowedOtas"
      :shops="shopsItems"
      :car-classes="carClassesItems"
      :shops-loading="shopsItemsLoading"
      :car-classes-loading="carClassesItemsLoading"
      :insurance-list="insuranceList"
      :time-slot-list="timeSlotList"
      :departure-date="departureDate"
      :disabled-save="disabledSave"
      @change:ota="handleChangeFilter('ota', $event)"
      @change:shop="handleChangeFilter('shop', $event)"
      @change:car-class="handleChangeFilter('carClass', $event)"
      @change:insurance="handleChangeFilter('insurance', $event)"
      @change:departure-date="handleChangeDepartureDate($event)"
      @save-changes="handleSaveChanges"
    )
    .calendar-wrapper
      AppOverlayLoader(:state="loading")
      Calendar(
        v-if="!isEmpty(departureDate) && isValidFilters"
        :key="generatedId"
        :current-plan="currentPlan"
        :changed-prices="changedPrices"
        :date-range="dateRange"
        :filters="filters"
        :rental-periods="rentalPeriods"
        :competitors="competitors"
        :recommended-prices="recommendedPrices"
        :invalid-prices="invalidPrices"
        :just-updated-dates="justUpdatedDates"
        @change="setChangedPrices"
      )
</template>

<script>
  // store modules
  import shopMatchingModule from "@/config/store/matching/shop"
  import priceManagementModule from "@/config/store/price_management"
  import pricesCarClassesModule from "@/config/store/price_management/car_class"
  import competitorsModule from "@/config/store/competitors/prices"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  // components
  import SavingConfirmation from "@/pages/PriceManagement/PriceCalendar/SavingConfirmation"

  // misc
  import { eachDayOfInterval, isWithinInterval, format } from "date-fns"
  import { transform, snakeCase, isEmpty, some, reduce, forEach, assign, keys, merge, isDate } from "lodash-es"
  import { SERIALIZER_VIEW_FILTERS, DATE_FNS_DATE_FORMAT } from "@/config/constants"
  import { showToast } from "@/helpers/toasts"
  import { isAutoApplyingRecommendedPrices, isAutoSetPrices } from "@/helpers/price-calendar"
  import { invalidPrices } from "./pricesValidation"
  import withWebSocket from "@/mixins/withWebSocket"
  import { mapGetters } from "vuex"
  import loadFromQuery from "./mixins/loadFromQuery"

  const priceManagementMixin = withStoreModule(priceManagementModule, {
    name: "priceManagement",
    readers: {
      currentPlan: "item",
      pricesLoading: "loading",
      filters: "filters",
      rentalPeriods: "rentalPeriodList",
      insuranceList: "insuranceList",
      timeSlotList: "timeSlotList"
    },
    mutations: {
      changeFilters: "SET_FILTERS",
      setPlan: "SET_ITEM"
    },
    actions: {
      fetchPlan: "FETCH_ITEM",
      updatePricesRequest: "UPDATE_ITEM"
    }
  })

  const shopMatchingMixin = withStoreModule(shopMatchingModule, {
    name: "pricesShops",
    readers: {
      shopsItems: "items",
      shopsItemsLoading: "loading"
    },
    actions: { fetchShopsItemsAction: "FETCH_ITEMS" }
  })

  const pricesCarClassesMixin = withStoreModule(pricesCarClassesModule, {
    name: "pricesCarClasses",
    readers: {
      carClassesItems: "items",
      carClassesItemsLoading: "loading"
    },
    actions: { fetchCarClassesItemsAction: "FETCH_ITEMS" }
  })

  const competitorsMixin = withStoreModule(competitorsModule, {
    name: "competitors",
    readers: {
      competitors: "items",
      competitorsLoading: "loading"
    },
    actions: {
      fetchCompetitorsAction: "FETCH_ITEMS"
    }
  })

  export default {
    components: {
      Filters: () => import("./Filters"),
      Calendar: () => import("./Calendar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [
      withWebSocket,
      priceManagementMixin,
      shopMatchingMixin,
      pricesCarClassesMixin,
      withConfirmation,
      competitorsMixin,
      loadFromQuery
    ],

    async mounted() {
      await this.$store.dispatch("FETCH_ORGANIZATION_OTA_LIST")
      // reload prices based on current filters or on query
      if (isEmpty(this.$route.query)) {
        this.loadFromFilters(this.filters)
      } else {
        this.loadFromQuery(this.$route.query)
      }

      this.webSocketSubscribe("PriceChannel", {
        received: data => {
          const { ota, shop, carClass } = this.filters

          if (ota.id === data.ota_id && shop.id === data.shop.id && carClass.id === data.car_class.id) {
            this.updateUrl()
          }
        }
      })
    },

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({
        to,
        next,
        isChanges: this.isUseConfirm,
        exitHandler: () => {
          this.setChangedPrices({})
        }
      })
    },

    data() {
      return {
        changedPrices: {},
        isApplyRecommendedPrices: isAutoApplyingRecommendedPrices(),
        generatedId: Math.random(),
        departureDate: [],
        justUpdatedDates: new Set()
      }
    },

    watch: {
      isPricesChanged(useConfirm) {
        this.setLogoutConfirm(useConfirm)
      }
    },

    computed: {
      ...mapGetters(["priceCalendarAllowedOtas"]),

      invalidPrices,

      loading({ pricesLoading, competitorsLoading }) {
        return pricesLoading || competitorsLoading
      },

      dateRange({ departureDate }) {
        const [start, end] = departureDate

        if (start && end) {
          return eachDayOfInterval({ start, end })
        } else {
          return []
        }
      },

      isPricesChanged({ changedPrices }) {
        return some(changedPrices, changesByRentalPeriod => !isEmpty(changesByRentalPeriod))
      },

      isValidFilters({ filters: { ota, shop, carClass } }) {
        return !isEmpty(ota) && !isEmpty(shop) && !isEmpty(carClass)
      },

      disabledSave({ isValidFilters, isPricesChanged, invalidPrices }) {
        return !isValidFilters || !isPricesChanged || !isEmpty(invalidPrices)
      },

      isUseConfirm({ isPricesChanged }) {
        return isPricesChanged && !isAutoSetPrices()
      },

      recommendedPrices() {
        return this.currentPlan.recommended_prices?.prices || {}
      },

      mappedRecommendedPrices() {
        return transform(this.recommendedPrices, (object, daysObject, rentalPeriod) => {
          return (object[rentalPeriod] = transform(daysObject, (self, value, date) => (self[date] = Number(value))))
        })
      },

      currentPlanPricesInFilter({ currentPlan, departureDate }) {
        if (isEmpty(currentPlan)) return {}
        const [start, end] = departureDate
        return reduce(
          currentPlan.prices,
          (prices, rp, key) => {
            forEach(rp, (price, date) => {
              if (isDate(start) && isDate(end) && isWithinInterval(new Date(date), { start, end })) {
                assign(prices[key] || (prices[key] = {}), { [date]: price })
              }
            })
            return prices
          },
          {}
        )
      }
    },

    methods: {
      isEmpty,

      setChangedPrices(updatedValues) {
        this.changedPrices = updatedValues
      },

      // Changing one filter clears others (except carClass)
      getNewFiltersByChange(filterName, filterValue = {}) {
        const { filters } = this

        switch (filterName) {
          case "ota":
            return { ...filters, ota: filterValue, shop: {}, carClass: {} }
          case "shop":
            return { ...filters, shop: filterValue, carClass: {} }
          case "carClass":
            return { ...filters, carClass: filterValue }
          default:
            return { ...filters, [filterName]: filterValue }
        }
      },

      handleChangeFilter(name, value) {
        this.handleChangeFilters(this.getNewFiltersByChange(name, value), name === "departureDate")
      },

      handleChangeFilters(filters, keepChanges = false) {
        this.$conditionalConfirm({
          useConfirm: this.isUseConfirm,
          handler: async () => {
            keepChanges || this.setChangedPrices({})

            await this.updateUrl(this.queryFromFilters(filters))

            if (this.isValidFilters) {
              this.isApplyRecommendedPrices = isAutoApplyingRecommendedPrices()
              this.generatedId = Math.random()
            } else {
              this.setPlan({})
            }
          }
        })
      },

      handleChangeDepartureDate(range) {
        const [start, end] = range
        this.$conditionalConfirm({
          useConfirm: this.isUseConfirm && this.hasChangesOutOfRange(start, end),
          handler: () => {
            this.setChangedPrices(this.getChangesInRange(start, end))
            this.departureDate = range
          }
        })
      },

      hasChangesOutOfRange(start, end) {
        return some(this.changedPrices, rentalPeriod => this.hasOutOfRange(rentalPeriod, start, end))
      },

      hasOutOfRange(rentalPeriod, start, end) {
        return some(keys(rentalPeriod), date => !isWithinInterval(new Date(date), { start, end }))
      },

      setChangesInCurrentRange() {
        const [start, end] = this.departureDate
        this.setChangedPrices(this.getChangesInRange(start, end))
      },

      actualizeJustUpdatedDates() {
        this.dateRange.forEach(date => this.justUpdatedDates.add(format(date, DATE_FNS_DATE_FORMAT)))
      },

      getChangesInRange(start, end) {
        let changedPricesTotal
        if (this.isApplyRecommendedPrices) {
          changedPricesTotal = merge(this.mappedRecommendedPrices, this.changedPrices)
        } else {
          changedPricesTotal = this.changedPrices
        }
        return reduce(
          changedPricesTotal,
          (changedInRange, rp, key) => {
            forEach(rp, (price, date) => {
              isWithinInterval(new Date(date), { start, end }) &&
                !this.justUpdatedDates.has(date) &&
                assign(changedInRange[key] || (changedInRange[key] = {}), { [date]: price })
            })
            return changedInRange
          },
          {}
        )
      },

      async fetchCalendarData(keepDepartureDate = false) {
        await this.fetchPlan()
        if (isEmpty(this.departureDate) || !keepDepartureDate) {
          this.setDepartureDateByCurrentPlan()
        }
        await this.fetchCompetitors()

        if (this.isApplyRecommendedPrices) {
          this.setRecommendedPricesAsChangedPrices()
        }
      },

      setDepartureDateByCurrentPlan() {
        this.departureDate = [new Date(this.currentPlan.open_start_date), new Date(this.currentPlan.open_end_date)]
      },

      setRecommendedPricesAsChangedPrices() {
        this.setChangedPrices(this.mappedRecommendedPrices)
      },

      validateField(fieldKey) {
        if (isEmpty(this.filters[fieldKey])) {
          const field = this.$t(`price_calendar.filters.${snakeCase(fieldKey)}`)

          showToast({ text: this.$t("errors.required_field", { field }) })
        }
      },

      async handleSaveChanges() {
        this.validateField("ota")
        this.validateField("shop")
        this.validateField("carClass")

        await this.$conditionalConfirm({
          useConfirm: this.isPricesChanged,
          component: SavingConfirmation,
          handler: async () => {
            if (this.isValidFilters && this.isPricesChanged) {
              try {
                await this.updatePrices()
                this.actualizeJustUpdatedDates()
                this.setChangesInCurrentRange()
              } catch (_) {
                return false
              }
            }
          }
        })
      },

      async updatePrices() {
        await this.updatePricesRequest({
          changes: this.changedPrices,
          departureDate: this.departureDate,
          isApplyRecommendedPrices: this.isApplyRecommendedPrices
        })
      },

      fetchShopsItems() {
        return this.fetchShopsItemsAction({
          pagination: { _disabled: true },
          ota_id: this.filters.ota.id,
          having_basic_plans: true,
          synced_only: true,
          active_only: true
        })
      },

      fetchCarClassesItems() {
        return this.fetchCarClassesItemsAction({
          shop_id: this.filters.shop.id,
          ota_id: this.filters.ota.id,
          having_basic_plans: true,
          serializer_view: SERIALIZER_VIEW_FILTERS,
          synced_only: true,
          active_only: true
        })
      },

      async fetchCompetitors() {
        await this.fetchCompetitorsAction(this.filters)
      }
    }
  }
</script>

<style lang="sass" scoped>
  .calendar-wrapper
    min-height: 400px
    position: relative
</style>
