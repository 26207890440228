import { reduce, get, forEach, isEmpty } from "lodash-es"

const getPrice = (changedPrices, currentPrices, date, rentalPeriod) => {
  const changed = get(changedPrices, `${rentalPeriod}.${date}`)

  return changed === 0 ? changed : changed || get(currentPrices, `${rentalPeriod}.${date}.value`)
}

const getRentalPeriodsPrices = (
  changedPrices,
  currentPrices,
  date,
  prevRentalPeriod,
  currentRentalPeriod,
  nextRentalPeriod
) => {
  const prevRPPrice = getPrice(changedPrices, currentPrices, date, prevRentalPeriod)
  const currentRPPrice = getPrice(changedPrices, currentPrices, date, currentRentalPeriod)
  const nextRPPrice = getPrice(changedPrices, currentPrices, date, nextRentalPeriod)

  return { prevRPPrice, currentRPPrice, nextRPPrice }
}

const isValidPriceByDate = ({ currentRP, prevRPPrice, currentRPPrice, nextRPPrice }) => {
  switch (currentRP) {
    case "day1":
      if (currentRPPrice > 0 && currentRPPrice < nextRPPrice) return true
      break
    case "days3":
      if (currentRPPrice > prevRPPrice) return true
      break
    case "late":
      if (currentRPPrice > 0) return true
      break
    default:
      if (currentRPPrice > 0 && currentRPPrice > prevRPPrice && currentRPPrice < nextRPPrice) return true
      break
  }

  return false
}

const validatePrices = ({
  currentPricesByDate,
  changedPricesByRP,
  currentPricesByRP,
  prevRP,
  currentRP,
  nextRP,
  byPeriodObj
}) => {
  forEach(currentPricesByDate, (currentPrice, date) => {
    const { prevRPPrice, currentRPPrice, nextRPPrice } = getRentalPeriodsPrices(
      changedPricesByRP,
      currentPricesByRP,
      date,
      prevRP,
      currentRP,
      nextRP
    )

    if (isValidPriceByDate({ currentRP, prevRPPrice, currentRPPrice, nextRPPrice })) return

    byPeriodObj[currentRP] ||= []
    byPeriodObj[currentRP].push(date)
  })
}

export const invalidPrices = ({ currentPlanPricesInFilter, changedPrices, rentalPeriods }) => {
  if (isEmpty(currentPlanPricesInFilter)) return {}

  return reduce(
    rentalPeriods,
    (byPeriodObj, { value: currentRP }, index) => {
      const prevRP = get(rentalPeriods, `${index - 1}.value`)
      const nextRP = get(rentalPeriods, `${index + 1}.value`)

      const changedPricesByRP = changedPrices
      const currentPricesByDate = currentPlanPricesInFilter[currentRP]

      validatePrices({
        currentPricesByDate,
        changedPricesByRP,
        currentPricesByRP: currentPlanPricesInFilter,
        prevRP,
        currentRP,
        nextRP,
        byPeriodObj
      })

      return byPeriodObj
    },
    {}
  )
}
